
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NewsView',
  components: {},
  data() {
    return {
      index: this.$route.query.index,
      news: [
        {
          tittle: '炔丙基半胱氨酸膠囊已獲中國藥品監督管理局臨床試驗申請受理',
          img: [
            require('/src/assets/news/accept.png'),
            require('/src/assets/news/0-2.png')
          ],
          p: '炔丙基半胱氨酸（SPRC）膠囊已獲中國藥品監督管理局臨床試驗申请受理。適應症分組：風濕性疾病及免疫藥物，分別為第一50mg規格，第二100mg規格。\n' +
              '申請事項:境內生產藥品註冊臨床試驗\n' +
              'Angiogenesis 產品名稱:炔丙基半胱氨酸膠囊\n' +
              '受理号:CXHL2300748\n' +
              'H.S 受理号:CXHL2300749 \n' +
              '受理日:2023年7月7日。'
        },
        {
          tittle: '依諾科技創始人朱依諄當選歐洲科學與藝術學院院士',
          img: [
            require('/src/assets/news/C_ZhuYiZhun.jpg')
          ],
          p: '依諾科技創始人朱依諄先生近日當選歐洲科學與藝術院院士。\n' +
              ' \n' +
              '\n' +
              '據悉，歐洲科學與藝術院是世界科學和藝術領域享有聲譽的學術組織，至今已有逾2000位院士，其中37位為諾貝爾獎得主，是諾貝爾獎獲得者比例最高的科學院之一。歐洲科學與藝術院目前設有人文、醫學、藝術、自然科學、社會科學、法律及經濟、技術與環境科學等7個學部。目前為止，共有約20名中國科學院、中國工程院院士及傑出學者當選歐洲科學與藝術院院士。\n' +
              ' \n' +
              '\n' +
              '朱依諄曾獲得國家傑出青年科學基金、教育部長江學者特聘教授、國家重大研究計劃（973）首席科學家、國家重大新藥創制大平台首席科學家等榮譽，也是國家藥典委員會委員、國務院僑辦創新委員會委員、上海市白玉蘭榮譽獎獲得者和國家衛健委《中國臨床用藥》（第一版）主編、《藥理學》國家規劃教材（第七、第八版）和英文版（第一版）主編。他任澳門中華醫學會榮譽會長，幷兼任澳科大第一附屬醫院的心內科主任醫師。朱依諄發表了330餘篇科學論文，被引用超過16500餘次（h-index 69，i10 index 245），入選全球頂尖前10萬名科學家排名和斯坦福大學全球前 2%頂尖科學家榜單的排名。他研發的2個first-in-class新藥分別在中、美進入了臨床I期和II期研究。\n',
          source: '原文摘自人民網http://hm.people.com.cn/n1/2023/0509/c42272-32682262.html'

        },
        {
          tittle: '「第十一屆亞洲藥學院校聯合會會議」順利舉辦',
          img: [
            require('/src/assets/news/AASP.jpg'),
            require('/src/assets/news/2-2.jpg'),
            require('/src/assets/news/2-3.jpg')
          ],
          p: '2023年8月4日至5日，由澳門科技大學主辦的「第十一屆亞洲藥學院校聯合會會議」在澳門科技大學順利舉行。會上，依諾科技創始人、澳科大協理副校長、醫學院（藥學院）院長朱依諄院士當選亞洲藥學院校聯合會（AASP）主席。\n' +
              '\n' +
              '亞洲藥學院校聯合會（Asian Association of Schools of Pharmacy，簡稱AASP）成立於2001年4月，由新加坡國立大學、香港中文大學和台灣大學等聯合創立。成員為來自亞洲的日本、韓國、新加坡、印度、印尼、菲律賓、馬來西亞、泰國、越南、伊朗、紐西蘭、澳大利亞以及中國的各大學的藥學院、系和獨立的藥科大學，亞洲藥學院校聯合會是一個非營利的民間專業組織，致力於服務各個成員單位，以促進亞太區域藥學教育和藥學研究的發展。是次會議為來自亞洲不同藥學院的學術界和學生之間的互動和交流提供寶貴的機會。\n' +
              '\n' +
              '在會上，朱依諄院士獲當選爲下一屆AASP主席，其表示將進一步促進亞洲及大洋洲高等教育團體藥學教育工作者之間有更多交流。AASP也會通過提高資源共用，以及在教學、科研和服務方面，為教師和學生提供更多機會，推動亞太地區藥學共同進步和發展，促進亞太與世界各藥學團體之間的交流與合作，為全人類的健康做出更多貢獻。\n' +
              ' \n' +
              '在這次精彩紛呈、充滿啟發的學術競賽上，參賽者共同經歷了知識的碰撞、思想的交流，不僅深化了學術領域的探討，更增進了相互之間的了解與合作。圍繞著諸多重要議題展開了深入的研討，分享了創新的理念與前沿的研究成果，使得這個學術盛宴成為了青年學者共同成長與進步的契機。參賽者展望未來，將繼續保持學術交流的活躍，秉持開放、合作、創新的精神，共同推動藥學學科的發展；攜手前行，迎接新的挑戰。與會者在會後參觀了校園及大學國家重點實驗室對澳門科技大學的飛速發展和科學成就表示出極大地佩服。\n',
          source: '本文摘自 https://mp.weixin.qq.com/s/vzo56Ic0li9CYnPrEYujWA'
        },
        {
          tittle: '依諾科技集團【原創炎症及免疫藥物的發現和臨床研究】榮獲第十三屆中國創新創業大賽（廣東賽區）\n'+
          '暨第十二屆“珠江天使杯”科技創新創業大賽初創組一等獎',
          img: [
            require('/src/assets/news/3-1.jpg'),
            require('/src/assets/news/3-2.jpg'),
            require('/src/assets/news/3-3.jpg')
          ],
          p: '依諾科技創始人朱依諄先生近日當選歐洲科學與藝術院院士。\n' +
              ' \n' +
              '\n' +
              '2024年10月25日，為落實創新驅動發展戰略，強化企業科技創新主體地位，推動科技創新和產業創新深度融合，發展新質生產力，由廣東省科學技術廳主辦，廣東省生產力促進中心及廣東省科技金融促進會承辦及各地市科技局承協辦的第十三屆中國創新創業大賽（廣東賽區）暨第十二屆“珠江天使杯”科技創新創業大賽總決賽暨頒獎典禮在廣州南沙香港科技大學廣州校區舉行。\n' +
              ' \n' +
              '\n' +
              '中國創新創業大賽是全國規模最大、輻射最廣、影響力最強的創新創業賽事，本次會議的主題是“因創而聚，向新同行”。大賽經過初賽、复赛及总決賽，其中初賽共計2000家企業報名參賽，最终100家企业進入总決賽；評選流程依據中國創新創業大賽組委會辦公室制定的統一參賽條件、評審規則及評選標準，對參賽企業實行嚴格的參賽資格審查，並建立嚴格的專案篩選程式及公開透明的選拔機制，遵循“公開、公平、公正、競爭擇優”的原則，圍繞“技術和產品創新”、“市場前景和競爭”、“管理團隊”、“財務及融資”等方面對參賽專案進行評選，篩選出最具市場潛力和投資價值的專案和企業評委獨立打分、當場亮分,現場公佈企業得分。\n'+
              ' \n' +
              '\n' +
              '最終經過激烈角逐朱依諄院士的【原創炎症及免疫藥物的發現和臨床研究】項目超越香港大學、北京大學、中國科學院、上海交通大學及哈爾濱工業大學等創業團隊，以總分第一名成績榮獲廣东賽區初創組一等獎。\n'+
              '\n'+
              '\n'+
              '並預定於2024年11月18日至21日參加江蘇省泰州市舉辦的【第十三屆中國創新創業大賽生物醫藥全國賽】。'
        },
      ]
    }
  }
})
