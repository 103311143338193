import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from "@/views/NewsView.vue";
import ResView from "@/views/ResView.vue";
import ContactView from "@/views/ContactView.vue";
import ZhaobiaoView from "@/views/ZhaobiaoView.vue";
import Zhaobiao2View from "@/views/Zhaobiao2View.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/res',
    name: 'res',
    component: ResView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/zhaobiao',
    name: 'zhaobiao',
    component: ZhaobiaoView
  },
  {
    path: '/zhaobiao2',
    name: 'zhaobiao2',
    component: Zhaobiao2View
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
