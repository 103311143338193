import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-top":"50px"} }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { class: "main-img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "main-p" }
const _hoisted_6 = { style: {"font-size":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.news[_ctx.index].tittle), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news[_ctx.index].img, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "main-img-item",
            key: item
          }, [
            _createElementVNode("img", {
              src: item,
              alt: ""
            }, null, 8, _hoisted_4)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.news[_ctx.index].p), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.news[_ctx.index].source), 1)
      ])
    ])
  ]))
}