
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResView',
  components: {},
  data() {
    return
  }
})
