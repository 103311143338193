
import { defineComponent } from 'vue'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
export default defineComponent({
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      news: [
        {
          'img': require('/src/assets/news/accept.png'),
          'text': '炔丙基半胱氨酸膠囊已獲中國藥品監督管理局臨床試驗申請受理',
          'link': ''
        },
        {
          'img': require('/src/assets/news/C_ZhuYiZhun.jpg'),
          'text': '依諾科技創始人朱依諄當選歐洲科學與藝術學院院士',
          'link': ''
        },
        {
          'img': require('/src/assets/news/AASP.jpg'),
          'text': '「第十一屆亞洲藥學院校聯合會會議」順利舉辦',
          'link': ''
        },
      ],
      newProduct: [
        {
          img: require('/src/assets/medicine/SCM198_1.png'),
          tittle: 'SCM-198',
          p: '中藥來源小分子活性物質的轉換醫學研究\n' +
              '益母草鹼藥用晶型獲I類原創化學藥物臨床實驗批件'
        },
        {
          img: require('/src/assets/medicine/ZYZ802_1.png'),
          tittle: 'ZYZ-802',
          p: 'ZYZ-802抗炎功效能顯著抵抗炎症引發的類風濕疾病\n' +
              '能夠顯著抑制類風濕的發展和改善預後'
        },
      ]
      // common: [
      //   {
      //     'tittle': '处方前研究',
      //     'p': '原料藥研究：物理性質、化學性質、生物性質研究，原料雜質譜原輔料相容性試驗：選擇普通片劑常用輔料進行原輔料相容性考察'
      //   },
      //   {
      //     'tittle': '處方工藝研究',
      //     'p': '粉末混合工藝、乾法制粒方法研究：原輔料與處理，混合方法及時間，顆粒粒度分佈、流動性、吸濕曲線考察，壓片，包衣研究'
      //   },
      //   {
      //     'tittle': '製劑分析方法開發及驗證',
      //     'p': '製劑分析方法開發，質量標準草案起草：有關物質分析，溶出測定，微生物測定，清潔確認用分析方法'
      //   },
      //   {
      //     'tittle': '製備申報批樣本試驗',
      //     'p': 'IND申報批樣本試驗（每個規格1批，批量暫定5000片）乙方進行申報批樣品試驗（乙方承擔輔料及包材的採購與檢驗放行工作）'
      //   },
      //   {
      //     'tittle': '臨床批（GMP）',
      //     'p': 'GMP批次（每個規格1批，批量暫定10000片）'
      //   },
      //   {
      //     'tittle': '製劑質量研究',
      //     'p': '兩批放大生產樣品全檢'
      //   },
      //   {
      //     'tittle': '樣品穩定性試驗',
      //     'p': '樣品性狀、溶出度、有關物質、水分、含量、微生物限度、晶型試驗，每個規格各1批IND申報批與1批臨床批'
      //   },
      //   {
      //     'tittle': '申報資料',
      //     'p': '資料3.2.P.1-3.2.P.8及2.3.P（NMPA）'
      //   },
      // ]
    }
  },
  methods: {
    jump(index: number){
      this.$router.push({
            path: `/news`,
            query: {
              index: index
            }
          }
          // `/news/${index}`
      )
    }
  }
});
