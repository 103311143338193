<template>
  <div class="footer">
    <h1>
      香港新界沙田香港科學園科技大道西17號17W617室<br>
      INNODRUG LAB. (HONGKONG) CO., LIMITED
    </h1>
  </div>
</template>

<style lang="scss">
.footer {
  background: white;
  >h1 {
    color: black;
    //padding: 10px;
    font-size: 15px;
    line-height: 24px;
  }
}
</style>
<script setup lang="ts">

</script>