
import {defineComponent} from "vue";

export default defineComponent({
  methods: {
    link(url: string) {
      this.$router.push(url)
    },
    jump(index: number){

      this.$router.push({
            path: `/news`,
            query: {
              index: index
            }
          }
          // `/news/${index}`
      )
    }
  }
})
